.contact{
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
  color: #000000;

  @media screen and (max-width: 750px) {
    padding: 0px;
  }
  .contact-box{
    max-width: 1440px;
    width: 100%;
    padding: 0 50px;
    @media screen and (max-width: 750px) {
      padding: 0 25px;
    }
    p{
      font-size: 16px;
    }

    .form-row.filed{
      @media screen and (max-width: 750px) {
        flex-direction: column !important;
      }      
    }
    .form-row{
      display: flex;
      gap: 20px;
      position: relative;
      p{
        padding-left: 30px;
      }
    }
    .form{
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 30px;

      input[type="checkbox"]{
        position: absolute !important;
        top: 0 !important;
      }

      input[type="text"], textarea{
        width: 100%;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        padding: 15px;
        box-shadow: 0px 4px 15px 0 rgb(0 0 0 / 6%);
        font-family: "FuturaEUNormal";
        font-size: 15px;
        &:active, &:focus{
          outline-color: #1f82de;
        }
      }
      textarea{
        min-height: 200px;
      }
      .btn-row {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}
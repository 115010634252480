.wallet{
  display: flex;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 140px;
  @media screen and (max-width: 1440px) {
    padding: 0 25px;
  }
  @media screen and (max-width: 840px) {
    padding: 0;
    margin-top: 80px;
  }

  .wallet-box{
    width: 100%;
    margin: auto;
    background: linear-gradient(360deg, #040709 0%, #162c35 100%);
    display: flex;
    justify-content: center;
    padding: 25px 50px;
    border-radius: 20px;
    color: #fff;
    font-size: 58px;
    font-weight: 500;
    font-family: "FuturaHvEU";
    align-items: center;
    @media screen and (max-width: 840px) {
      border-radius: 0px;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto auto;
      gap: 28px;
      grid-template-areas:
        "icon"
        "."
        ".";
      padding-bottom: 50px;
    }
    .icon { 
      grid-area: icon; 
      @media screen and (max-width: 840px) {
        text-align: center;
      }
    }
    .wallet-item{
      text-align: center;
      .wallet-item-name{
        font-size: 14px;
      }
      .wallet-item-value{
        font-size: 58px;
        font-weight: 500;
        letter-spacing: -1.8px;
        @media screen and (max-width: 1020px) {
          font-size: 48px;
        }
      }
      .wallet-item-coin{
        font-size: 14px;
      }
    }

  }
 
  .wallet-info{
    margin: 40px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 50px;
    grid-template-areas: ". .";
    width: 100%;

    @media screen and (max-width: 840px) {
      grid-template-columns: 1fr;
      grid-template-areas: ".";
      padding: 0 25px;
      gap: 30px;
    }
    h3{
      font-size: 16px;
      margin: 0 0 10px 0;
    }
    p{
      font-size: 14px;
      line-height: 1.5;
    }
  }
}
.social{
  display: flex;
  justify-content: flex-end;
  ul{
    display: flex;
    list-style: none;
    gap: 6px !important;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 570px) {
      gap: 4px !important;
    }
    @media screen and (max-width: 368px) {
      gap: 6px !important;
    }
    li{
      a{
        img{
          max-width: 28px;
          transition: all ease-in-out .2s;
          @media screen and (max-width: 570px) {
            max-width: 20px;
          }
          &:hover{
            filter: brightness(.8);
          }
        }        
      }
    }
  }
}
.button {
  background-color: #60d5e7;
  color: #000;
  border: 0;
  font-family: "FuturaHvEU";
  font-size: 21px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 22px;
  letter-spacing: -0.8px;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
}
.button svg {
  transition: all ease-in-out 0.2s;
}
.button:hover {
  background-color: #6be0f2;
}
.button:hover svg {
  margin-left: 10px;
}/*# sourceMappingURL=style.css.map */
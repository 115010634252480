.history {
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background-color: #0b0b0b;
  background-image: url("./img/bg.png");
  color: #fff;
  padding: 50px 0 50px 0;
  background-size: 100%;
  background-position: center;
}
@media screen and (max-width: 750px) {
  .history {
    padding: 50px 0px;
    background-image: none;
    background: linear-gradient(180deg, rgb(16, 30, 39) 0%, rgb(31, 58, 70) 100%) !important;
  }
}
.history .history-box {
  max-width: 1440px;
  width: 100%;
  padding: 0 50px;
}
.history .history-box .tg-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  justify-items: end;
  margin-bottom: -50px;
}
@media screen and (max-width: 1100px) {
  .history .history-box .tg-grid {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
    justify-items: center;
  }
}
.history .history-box .tg-grid p {
  font-size: 15px;
  line-height: 1.5;
}
@media screen and (max-width: 750px) {
  .history .history-box .tg-grid p {
    text-align: center;
  }
}
.history .history-box .tg-grid .lead {
  font-size: 20px;
  margin: 45px 0 20px 0;
  font-weight: 700;
}
.history .history-box .tg-grid .tg-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.history .history-box .tg-grid .tg-box img {
  width: 100%;
  max-width: 500px;
  position: relative;
  bottom: 0px;
}

.history-info {
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  color: #000;
  padding: 50px 0 50px 0;
  background-size: 100%;
  background-position: center;
}
@media screen and (max-width: 750px) {
  .history-info {
    padding: 50px 25px;
  }
}
.history-info .history-info-grid {
  max-width: 1440px;
  width: 100%;
  padding: 0 50px;
  display: flex;
  gap: 50px;
  flex-direction: column;
}
@media screen and (max-width: 750px) {
  .history-info .history-info-grid {
    padding: 0px;
  }
}
.history-info .history-info-grid .history-info-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 50px;
  grid-template-areas: ". .";
  align-items: center;
}
@media screen and (max-width: 1100px) {
  .history-info .history-info-grid .history-info-column {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
.history-info .history-info-grid .history-info-column .yt-row {
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: space-around;
}
.history-info .history-info-grid .history-info-column .yt-row .yt-link {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1100px) {
  .history-info .history-info-grid .history-info-column .yt-row .yt-link img {
    width: 100%;
  }
}
.history-info .history-info-grid .history-info-column .yt-row .yt-link .ytIcon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 69px;
  height: 49px;
  cursor: pointer;
}
.history-info .history-info-grid .history-info-column p {
  font-size: 15px;
  line-height: 1.4;
}
@media screen and (max-width: 750px) {
  .history-info .history-info-grid .history-info-column p {
    text-align: center;
  }
}/*# sourceMappingURL=style.css.map */
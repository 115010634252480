.wallet {
  display: flex;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 140px;
}
@media screen and (max-width: 1440px) {
  .wallet {
    padding: 0 25px;
  }
}
@media screen and (max-width: 840px) {
  .wallet {
    padding: 0;
    margin-top: 80px;
  }
}
.wallet .wallet-box {
  width: 100%;
  margin: auto;
  background: linear-gradient(360deg, #040709 0%, #162c35 100%);
  display: flex;
  justify-content: center;
  padding: 25px 50px;
  border-radius: 20px;
  color: #fff;
  font-size: 58px;
  font-weight: 500;
  font-family: "FuturaHvEU";
  align-items: center;
}
@media screen and (max-width: 840px) {
  .wallet .wallet-box {
    border-radius: 0px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto auto;
    gap: 28px;
    grid-template-areas: "icon" "." ".";
    padding-bottom: 50px;
  }
}
.wallet .wallet-box .icon {
  grid-area: icon;
}
@media screen and (max-width: 840px) {
  .wallet .wallet-box .icon {
    text-align: center;
  }
}
.wallet .wallet-box .wallet-item {
  text-align: center;
}
.wallet .wallet-box .wallet-item .wallet-item-name {
  font-size: 14px;
}
.wallet .wallet-box .wallet-item .wallet-item-value {
  font-size: 58px;
  font-weight: 500;
  letter-spacing: -1.8px;
}
@media screen and (max-width: 1020px) {
  .wallet .wallet-box .wallet-item .wallet-item-value {
    font-size: 48px;
  }
}
.wallet .wallet-box .wallet-item .wallet-item-coin {
  font-size: 14px;
}
.wallet .wallet-info {
  margin: 40px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 50px;
  grid-template-areas: ". .";
  width: 100%;
}
@media screen and (max-width: 840px) {
  .wallet .wallet-info {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
    padding: 0 25px;
    gap: 30px;
  }
}
.wallet .wallet-info h3 {
  font-size: 16px;
  margin: 0 0 10px 0;
}
.wallet .wallet-info p {
  font-size: 14px;
  line-height: 1.5;
}/*# sourceMappingURL=style.css.map */
.sticky-nav .sticky-navbar-nav {
  padding: 0;
  height: 80px;
  width: 100%;
}
@media screen and (max-width: 368px) {
  .sticky-nav .sticky-navbar-nav {
    height: 100px;
  }
}
.sticky-nav .sticky-navbar-nav .navigation {
  display: grid;
  grid-template-columns: 235px 1fr 235px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". . .";
  position: absolute;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  left: 0;
  right: 0;
  color: #fff;
  align-items: center;
}
@media screen and (max-width: 1440px) {
  .sticky-nav .sticky-navbar-nav .navigation {
    padding: 0 25px;
  }
}
@media screen and (max-width: 980px) {
  .sticky-nav .sticky-navbar-nav .navigation {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 368px) {
  .sticky-nav .sticky-navbar-nav .navigation {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
    display: grid;
    justify-items: center;
    gap: 10px;
  }
}
.sticky-nav .sticky-navbar-nav .navigation .logo {
  width: 270px;
}
.sticky-nav .sticky-navbar-nav .navigation .logo img {
  width: 100%;
  max-width: 270px;
}
@media screen and (max-width: 570px) {
  .sticky-nav .sticky-navbar-nav .navigation .logo img {
    max-width: 150px;
  }
}
@media screen and (max-width: 980px) {
  .sticky-nav .sticky-navbar-nav .navigation .top-menu {
    display: none;
  }
}
.sticky-nav .sticky-navbar-nav .navigation .top-menu ul {
  display: flex;
  list-style: none;
  gap: 20px;
  align-items: center;
  margin: auto;
  padding: 0;
  justify-content: center;
}
.sticky-nav .sticky-navbar-nav .navigation .top-menu ul li {
  font-family: "FuturaHvEUNormal";
  text-transform: uppercase;
  font-size: 14px;
}
.sticky-nav .sticky-navbar-nav .navigation .top-menu ul li a {
  transition: color ease 0.2s;
  color: #fff;
  text-decoration: none;
}
.sticky-nav .sticky-navbar-nav .navigation .top-menu ul li a:hover {
  color: #5dd4e6;
}/*# sourceMappingURL=style.css.map */
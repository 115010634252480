@charset "UTF-8";
.roadmap {
  display: flex;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 80px;
}
@media screen and (max-width: 1440px) {
  .roadmap {
    padding: 0 25px;
  }
}
.roadmap h2 {
  margin-bottom: 20px;
}
@media screen and (max-width: 750px) {
  .roadmap h2 {
    text-align: center;
    line-height: 1.1;
    margin-bottom: 0px;
  }
}
.roadmap .roadmap-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 25px;
  grid-template-areas: ". ." ". .";
}
@media screen and (max-width: 1150px) {
  .roadmap .roadmap-grid {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
.roadmap .roadmap-grid .roadmap-item {
  background: linear-gradient(45deg, #101e27 0%, #19323c 100%);
  border-radius: 20px;
  color: #fff;
  padding: 50px;
  font-family: "FuturaHvEU";
}
@media screen and (max-width: 750px) {
  .roadmap .roadmap-grid .roadmap-item {
    padding: 30px;
  }
}
.roadmap .roadmap-grid .roadmap-item .title-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: -20px;
}
.roadmap .roadmap-grid .roadmap-item .title-row h3 {
  font-size: 32px;
  margin: 0;
}
@media screen and (max-width: 750px) {
  .roadmap .roadmap-grid .roadmap-item .title-row h3 {
    padding-left: 0px;
    font-size: 22px;
  }
}
.roadmap .roadmap-grid .roadmap-item .title-row h3 span {
  color: #60d5e7;
}
.roadmap .roadmap-grid .roadmap-item .title-row .meter {
  width: 80px;
  height: 80px;
  position: relative;
  top: -25px;
  right: -15px;
}
@media screen and (max-width: 750px) {
  .roadmap .roadmap-grid .roadmap-item .title-row .meter {
    width: 55px;
    top: -15px;
  }
}
.roadmap .roadmap-grid .roadmap-item .title-row .meter text {
  position: relative;
  top: 10px;
}
@media screen and (max-width: 750px) {
  .roadmap .roadmap-grid .roadmap-item .title-row + ul {
    padding-left: 18px;
  }
}
.roadmap .roadmap-grid .roadmap-item .checked {
  color: #60d5e7;
  position: relative;
}
.roadmap .roadmap-grid .roadmap-item .checked .icon-checked {
  position: absolute;
  margin-left: 4px;
  top: 2px;
  left: -26px;
  background: #152a33;
}
.roadmap .roadmap-grid .roadmap-item ul {
  font-size: 14px;
  font-family: "FuturaEUNormal";
  font-weight: 600;
  list-style: none;
}
.roadmap .roadmap-grid .roadmap-item ul li::before {
  content: "•";
  color: #60d5e7;
  font-size: 17px;
  position: absolute;
  margin-left: -20px;
  top: -2px;
}
.roadmap .roadmap-grid .roadmap-item ul li {
  line-height: 1.4;
  position: relative;
}
.roadmap .roadmap-grid .roadmap-item ul li ul li::before {
  content: "•";
  color: #60d5e7;
  font-size: 8px;
  position: absolute;
  margin-left: -16px;
  top: 4px;
}
.roadmap .roadmap-grid .roadmap-item ul li ul li ul {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.roadmap .roadmap-grid .roadmap-item ul li ul li ul li::before {
  content: "╾╾╾";
  color: #60d5e7;
  font-size: 8px;
  position: absolute;
  margin-left: -38px;
  top: 4px;
}
@media screen and (max-width: 750px) {
  .roadmap .roadmap-grid .roadmap-item ul li ul li ul li::before {
    content: "•";
    margin-left: -16px;
  }
}
.roadmap .roadmap-grid .roadmap-item ul li ul li ul li ul {
  padding-left: 18px;
}
.roadmap .roadmap-grid .roadmap-item ul li ul li ul li ul li::before {
  content: "•";
  color: #60d5e7;
  font-size: 8px;
  position: absolute;
  margin-left: -16px;
  top: 4px;
}
.roadmap .roadmap-grid .roadmap-item ul .light-font {
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media screen and (max-width: 750px) {
  .roadmap .roadmap-grid .roadmap-item ul .light-font {
    padding-left: 20px;
  }
}
.roadmap .roadmap-grid .roadmap-item ul .light-font li {
  line-height: 1.3;
  padding-bottom: 8px;
}/*# sourceMappingURL=style.css.map */
.how-buy {
  max-width: 1440px;
  width: 100%;
  margin: 100px auto 0 auto;
  background-color: #000;
  border-radius: 20px;
  position: relative;
}
@media screen and (max-width: 1440px) {
  .how-buy {
    width: calc(100% - 50px);
  }
}
@media screen and (max-width: 890px) {
  .how-buy {
    padding: 40px 0;
    border-radius: 0;
    width: 100%;
  }
}
.how-buy .disc {
  position: absolute;
  top: -50px;
  left: -100px;
}
@media screen and (max-width: 890px) {
  .how-buy .disc {
    display: none;
  }
}
.how-buy .how-buy-box {
  width: 100%;
  min-height: 700px;
  background-image: url("./img/bg.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  color: #fff;
  padding: 50px 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
}
@media screen and (max-width: 1080px) {
  .how-buy .how-buy-box {
    padding: 20px;
    gap: 20px;
  }
}
@media screen and (max-width: 890px) {
  .how-buy .how-buy-box {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: ".";
  }
}
@media screen and (max-width: 890px) {
  .how-buy .how-buy-box .instruction {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.how-buy .how-buy-box .instruction .steps {
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-top: 50px;
}
@media screen and (max-width: 890px) {
  .how-buy .how-buy-box .instruction .steps {
    align-items: center;
  }
}
@media screen and (max-width: 890px) {
  .how-buy .how-buy-box .instruction .steps .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
.how-buy .how-buy-box .instruction .steps .step .steps-title {
  font-size: 34px;
  font-family: "Tr2n";
  color: #ffd803;
  text-shadow: 0px 0px 3px #ffa800, 0px 0px 3px #ffa800;
  margin-bottom: 10px;
}
.how-buy .how-buy-box .instruction .steps .step p {
  font-size: 15px;
  max-width: 550px;
  line-height: 1.5;
}
@media screen and (max-width: 1080px) {
  .how-buy .how-buy-box .instruction .steps .step p {
    font-size: 13px;
  }
}
.how-buy .how-buy-box .instruction .steps .step p a {
  color: #ffd803;
}
.how-buy .how-buy-box .video {
  position: relative;
}
.how-buy .how-buy-box .video .video-title {
  display: inline-block;
  transform: rotate(90deg);
  position: absolute;
  right: -87px;
  top: 100px;
  color: #ffd804;
}
@media screen and (max-width: 1440px) {
  .how-buy .how-buy-box .video .video-title {
    right: -120px;
  }
}
@media screen and (max-width: 1350px) {
  .how-buy .how-buy-box .video .video-title {
    right: -140px;
  }
}
@media screen and (max-width: 1250px) {
  .how-buy .how-buy-box .video .video-title {
    right: -160px;
  }
}
@media screen and (max-width: 1150px) {
  .how-buy .how-buy-box .video .video-title {
    right: -170px;
  }
}
@media screen and (max-width: 1080px) {
  .how-buy .how-buy-box .video .video-title {
    transform: rotate(0deg);
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
  }
}
@media screen and (max-width: 890px) {
  .how-buy .how-buy-box .video .video-title {
    display: none;
  }
}
.how-buy .how-buy-box .video .video-box {
  position: absolute;
  width: 376px;
  height: 592px;
  left: 0;
  right: -151px;
  margin: auto;
  top: 0;
  bottom: 0;
}
@media screen and (max-width: 1080px) {
  .how-buy .how-buy-box .video .video-box {
    right: 0;
  }
}
@media screen and (max-width: 890px) {
  .how-buy .how-buy-box .video .video-box {
    position: relative;
  }
}
@media screen and (max-width: 473px) {
  .how-buy .how-buy-box .video .video-box {
    width: 100%;
  }
}
.how-buy .how-buy-box .video .video-box .cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  border-radius: 20px;
  border: 3px solid #bd9443;
  box-shadow: 0 0 20px 1px rgba(224, 138, 5, 0.69);
}
.how-buy .how-buy-box .video .video-box .cover img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 20px;
}
.how-buy .how-buy-box .video .video-box .cover .play-button {
  position: absolute;
  background: transparent;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 2;
}
.how-buy .how-buy-box .video .video-box .react-player {
  border-radius: 20px;
  overflow: hidden;
}/*# sourceMappingURL=style.css.map */
.header{
  background-image: url('./img/bg_hr.jpg');
  background-repeat: no-repeat;
  height: 100%;
  background-position: center bottom;
  background-size: auto;
  color: #fff;
  padding-top: 30vh;

  @media screen and (max-width: 750px) {
    padding-top: 100px;
  }

  .container{
    max-width: 1440px;
    width: 100%;
    margin: auto;
    text-align: center;
    height: 100%;
    min-height: 100vh;

    @media screen and (max-width: 750px) {
      padding-left: 30px;
      padding-right: 30px;
      box-sizing: border-box;
    }

    h2{
      font-size: 21px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      letter-spacing: unset;
      position: relative;
      top: -25px;
      align-items: center;

      img{
        transition: all ease-in-out .15s;
        position: relative;
        top: 4px;
        &:hover{
          opacity: .7;
        }
      }
    }

    h1{
      font-family: "FuturaHvEUNormal";
      margin: 0;
      padding: 0;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      font-size: clamp(70px, 7vw, 130px);
      line-height: 1;
      position: relative;
      width: 100%;
      align-items: center;
      @media screen and (max-width: 750px) {
        font-size: 50px;
      }
      .tron-logo{
        width: 100%;
        max-width: 400px;
        position: absolute;
        bottom: -50px;
        right: 10px;
        margin: auto;
        @media screen and (max-width: 1600px) {
          max-width: 300px;
          bottom: -55px;
        }
        @media screen and (max-width: 750px) {
          max-width: 259px;
          bottom: -45px;
          left: 0;
          right: 0;
        }
      }
      .h1row{
        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
        // gap: clamp(60px, 10vw, 145px);
        .row_with_hero{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          @media screen and (max-width: 750px) {
            flex-direction: column;
            gap: 0 !important;
            display: flex;
            align-items: center;
          }
          span{
            color: #58dee3;
            font-size: clamp(60px, 7vw, 110px);
            font-weight: 500;
            display: block;
            white-space: nowrap;
            @media screen and (max-width: 750px) {
              font-size: 50px;
            }
          }
          .tronguy {
            position: absolute;
            z-index: 3;
            width: 100%;
            max-width: 427px;
            @media screen and (max-width: 750px) {
              position: absolute;
              z-index: 3;
              width: 278px;
              max-width: 427px;
              left: 0 !important;
              top: 147px !important;
            }
          }
        }
        
      }
    }
    h3{
      font-family: "FuturaLtCnEUNormal";
      font-size: 20px;
      margin: 0;
      padding: 0 0 20px 0;
      font-weight: 100;
      color: #a6a6a6;
      letter-spacing: 2px;
      text-align: center;
      text-transform: uppercase;
      position: relative;
      width: 100%;
      @media screen and (max-width: 750px) {
        font-size: 15px;
        text-align: center;
      }
    }

    .pojazd{
      position: absolute;
      z-index: 0;
      left: 500px;
      right: 0;
      margin: 133px auto 0 auto;
      opacity: 0.81;
      width: 630px;
      bottom: 0;
      @media screen and (max-width: 1150px) {
        left: 25vw;
      }
      @media screen and (max-width: 1000px) {
        left: 0;
      }
      @media screen and (max-width: 750px) {
        display: none;
      }
    }
  }
}
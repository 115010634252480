.tokenomics {
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
  background-color: #0b0b0b;
  color: #fff;
  padding: 50px 0;
}
.tokenomics .tokenomics-box {
  max-width: 1440px;
  width: 100%;
  padding: 0 50px;
}
@media screen and (max-width: 750px) {
  .tokenomics .tokenomics-box {
    padding: 0px 25px;
  }
}
.tokenomics .tokenomics-box .toke-content {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.tokenomics .tokenomics-box .toke-content h3 {
  text-align: center;
  font-size: 22px;
}
.tokenomics .tokenomics-box .toke-content .toke-row-4, .tokenomics .tokenomics-box .toke-content .toke-row-3 {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.tokenomics .tokenomics-box .toke-content .toke-row-4 .toke-item, .tokenomics .tokenomics-box .toke-content .toke-row-3 .toke-item {
  font-size: 28px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  min-height: 100px;
  justify-content: center;
  text-align: center;
}
@media screen and (max-width: 1400px) {
  .tokenomics .tokenomics-box .toke-content .toke-row-4 .toke-item, .tokenomics .tokenomics-box .toke-content .toke-row-3 .toke-item {
    font-size: 25px;
    padding: 0 20px;
  }
}
@media screen and (max-width: 1300px) {
  .tokenomics .tokenomics-box .toke-content .toke-row-4 .toke-item, .tokenomics .tokenomics-box .toke-content .toke-row-3 .toke-item {
    font-size: 20px;
    word-break: break-all;
  }
}
@media screen and (max-width: 1300px) {
  .tokenomics .tokenomics-box .toke-content .toke-row-4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px;
    grid-template-areas: ". .";
  }
}
@media screen and (max-width: 560px) {
  .tokenomics .tokenomics-box .toke-content .toke-row-4 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 20px;
    grid-template-areas: ".";
  }
}
.tokenomics .tokenomics-box .toke-content .toke-row-4 .toke-item {
  background-color: #313131;
}
.tokenomics .tokenomics-box .toke-content .toke-row-4 .toke-item .mini {
  font-size: 14px;
  font-weight: 400;
  position: relative;
  top: 4px;
}
.tokenomics .tokenomics-box .toke-content .toke-row-4 .toke-item .toke-item-title {
  font-size: 16px;
  color: #ffd803;
}
@media screen and (max-width: 670px) {
  .tokenomics .tokenomics-box .toke-content .toke-row-3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 20px;
    grid-template-areas: ".";
  }
}
.tokenomics .tokenomics-box .toke-content .toke-row-3 .toke-item {
  background-color: #ffd803;
  color: #0b0b0b;
}
.tokenomics .tokenomics-box .toke-content .chart img {
  width: 100%;
  border-radius: 20px;
}
.tokenomics .tokenomics-box .toke-content .btn-row {
  margin: 10px 0px;
  display: flex;
  justify-content: center;
}/*# sourceMappingURL=style.css.map */
.buy{
  display: flex;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
  @media screen and (max-width: 1440px) {
    padding: 0 25px;
  }
  @media screen and (max-width: 750px) {
    margin-top: 50px;
  }
  h2{
    color: #000000;
  }
  .buy-row{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 1200px) {
      display: grid; 
      grid-template-columns: 1fr 1fr; 
      grid-template-rows: 1fr; 
      gap: 20px; 
      grid-template-areas: 
        ". ."; 
    }
    @media screen and (max-width: 320px) {
      display: grid; 
      grid-template-columns: 1fr ; 
      grid-template-areas: 
        "."; 
    }
    & > div{
      width: 100%;
    }
    & > div > div{
      border-radius: 13px;
      width: 100%;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 17px 61px 32px rgb(0 0 0 / 8%);
      padding: 0 20px;
      transition: all ease-in-out .2s !important;
      &:hover{
        opacity: .8;
      }
      img{
        @media screen and (max-width: 750px) {
          max-width: 200px;
        }
      }
    }
    .bbs{
      cursor: pointer;
      background-color: #000;
      color: #fff;
      svg{
        width: 100%;
        max-width: 250px;
      }
    }
    .bt{
      cursor: pointer;
      background: rgb(250,225,75);
      background: linear-gradient(90deg, rgba(250,225,75,1) 0%, rgba(244,184,31,1) 100%);

      img{
        width: 100%;
        max-width: 130px;
        @media screen and (max-width: 750px) {
          max-width: 90px;
        }
      }
    }
    .cs{
      cursor: pointer;
      background-color: #fff;
      svg{
        width: 100%;
        max-width: 250px;
      }
    }
    .ss{
      cursor: pointer;
      background: rgb(201,247,254);
      background: linear-gradient(90deg, rgba(201,247,254,1) 0%, rgba(141,238,252,1) 100%);
      svg{
        width: 100%;
        max-width: 270px;
      }
    }
  }
}

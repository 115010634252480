.news {
  display: flex;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: -180px;
}
@media screen and (max-width: 1440px) {
  .news {
    padding: 0 25px;
  }
}
@media screen and (max-width: 750px) {
  .news {
    margin-top: -170px;
  }
}
.news h2 {
  color: #fff;
  text-shadow: 1px -11px 20px rgba(19, 37, 50, 0.32), 1px 2px 15px rgba(19, 37, 50, 0.32), 1px 9px 20px rgba(19, 37, 50, 0.32);
}
.news .news-row {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  grid-template-areas: ".";
  width: 100%;
  margin: auto;
}
@media screen and (max-width: 1200px) {
  .news .news-row {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    grid-template-areas: ".";
    width: 100%;
  }
}
@media screen and (max-width: 320px) {
  .news .news-row {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
.news .news-row > div {
  width: 100%;
}
.news .news-row > div > div {
  border-radius: 13px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 17px 61px 32px rgba(0, 0, 0, 0.08);
  padding: 0;
  transition: all ease-in-out 0.2s !important;
}
.news .news-row > div > div:hover {
  opacity: 0.8;
}
@media screen and (max-width: 750px) {
  .news .news-row > div > div img {
    max-width: 200px;
  }
}
.news .news-box {
  max-width: 1000px;
  margin: 0 auto 50px auto;
}
.news .news-box p {
  font-size: 14px;
  line-height: 1.5;
}
.news .news-box h3 {
  font-size: 35px;
  margin: 0 0 15px 0;
}
.news .news-box .react-player {
  height: 555px !important;
}/*# sourceMappingURL=style.css.map */
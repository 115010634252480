.stats{
  display: flex;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 80px;
  @media screen and (max-width: 1440px) {
    padding: 0 25px;
  }
  @media screen and (max-width: 750px) {
    margin-top: 50px;
  }

  hr {
    display: none;
    @media screen and (max-width: 750px) {
      display: block;
    }
  }

  .stats-row-2{
    display: grid !important; 
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 20px; 
    grid-template-areas: 
      ". . . ."; 
    @media screen and (max-width: 750px) {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 40px 20px !important;
      grid-template-areas: ". .";
      justify-items: center;
      text-align: center;
      align-items: start !important;
    }
    
    .stats-item-4{
      @media screen and (max-width: 750px) {
        gap: 20px !important;
      }
      span{
        @media screen and (max-width: 750px) {
          font-size: 16px !important;
          line-height: 1;
        }
        br{
          @media screen and (min-width: 750px) {
            display: none;
          }          
        }

      }      
    }

  }

  .stats-row{
    display: flex;
    flex-direction: row;
    gap: 60px;
    justify-content: center;
    width: 100%;

    align-items: flex-end;
    @media screen and (max-width: 750px) {
      margin-bottom: 0px !important;
    }

    img{
      @media screen and (max-width: 750px) {
        max-width: 95px;
      }
    }

    @media screen and (max-width: 750px) {
      flex-direction: row;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
    }
  
    .stats-item-4{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      span{
        font-family: "FuturaHvEU";
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        @media screen and (max-width: 1000px) {
          font-size: 17px;
        }
      }
      img{
        max-width: 95px;
      }
    }

    .stats-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      .stats-item-text{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 22px 52px;
        gap: 0px;
        grid-template-areas:
            "."
            ".";
        justify-items: center;
        b{
          font-size: 40px;
          letter-spacing: -1px;
          @media screen and (max-width: 1250px) {
            font-size: 30px;
          }
          @media screen and (max-width: 810px) {
            font-size: 20px;
          }
        }
      }

      span{
        font-family: "FuturaHvEU";
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        @media screen and (max-width: 1000px) {
          font-size: 17px;
        }
      }
    }
  }
}
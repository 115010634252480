.history{
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background-color: #0b0b0b;
  background-image: url('./img/bg.png');
  color: #fff;
  padding: 50px 0 50px 0;
  background-size: 100%;
  background-position: center;

  @media screen and (max-width: 750px) {
    padding: 50px 0px;
    background-image: none;
    background: linear-gradient(180deg, rgba(16,30,39,1) 0%, rgba(31,58,70,1) 100%) !important;
  }
  .history-box{
    max-width: 1440px;
    width: 100%;
    padding: 0 50px;
    
    .tg-grid{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-template-areas: ". .";
      justify-items: end;
      margin-bottom: -50px;
      @media screen and (max-width: 1100px) {
        grid-template-columns: 1fr;
        grid-template-areas: ".";
        justify-items: center;
      }
      p{
        font-size: 15px;
        line-height: 1.5;
        @media screen and (max-width: 750px) {
          text-align: center;
        }
      }
      .lead{
        font-size: 20px;
        margin: 45px 0 20px 0;
        font-weight: 700;
      }
      .tg-box{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        img{
          width: 100%;
          max-width: 500px;
          position: relative;
          bottom: 0px;
        }        
      }

    }
  }
}
.history-info{
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  color: #000;
  padding: 50px 0 50px 0;
  background-size: 100%;
  background-position: center;

  @media screen and (max-width: 750px) {
    padding: 50px 25px;
  }

  .history-info-grid{
    max-width: 1440px;
    width: 100%;
    padding: 0 50px;
    display: flex;
    gap: 50px;
    flex-direction: column;
    @media screen and (max-width: 750px) {
      padding: 0px;
    }

    .history-info-column{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 50px;
      grid-template-areas: ". .";
      align-items: center;
      @media screen and (max-width: 1100px) {
        grid-template-columns: 1fr;
        grid-template-areas: ".";
      }
      
      .yt-row{
        display: flex;
        flex-direction: row;
        gap: 50px;
        justify-content: space-around;
        .yt-link{
          display: flex;
          position: relative;
          justify-content: center;
          align-items: center;
          img{
            @media screen and (max-width: 1100px) {
              width: 100%;
            }
          }
          .ytIcon{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto;
            width: 69px;
            height: 49px;
            cursor: pointer;
          }
        }        
      }


      p{
        font-size: 15px;
        line-height: 1.4;
        @media screen and (max-width: 750px) {
          text-align: center;
        }
      }
    }  
  }

}
.footer{
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
  background-color: #ebebeb;
  color: #000;
  padding: 50px 0;
  @media screen and (max-width: 750px) {
    padding: 50px 25px;
  }
  .footer-box{
    max-width: 1440px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    font-family: "FuturaEUNormal";
  }
}
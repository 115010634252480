@font-face {
  font-family: "FuturaHvEUNormal";
  src: local("FuturaHvEUNormal"), url("./fonts/FuturaHvEUNormal.ttf") format("opentype");
}
@font-face {
  font-family: "FuturaLtCnEUNormal";
  src: local("FuturaLtCnEUNormal"), url("./fonts/FuturaLtCnEUNormal.ttf") format("opentype");
}
@font-face {
  font-family: "FuturaEUNormal";
  src: local("FuturaEUNormal"), url("./fonts/FuturaEUNormal.ttf") format("opentype");
}
@font-face {
  font-family: "FuturaHvEU";
  src: local("FuturaHvEU"), url("./fonts/FuturaHvEU.ttf") format("opentype");
}
@font-face {
  font-family: "Tr2n";
  src: local("Tr2n"), url("./fonts/Tr2n.ttf") format("opentype");
}
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

h2 {
  font-size: 50px;
  letter-spacing: -1.2px;
  font-weight: 700;
  margin: 0;
}
@media screen and (max-width: 750px) {
  h2 {
    font-size: 40px;
    line-height: 1.2;
    text-align: center;
  }
}
h2 span {
  font-size: 65px;
  line-height: 1.2;
}
@media screen and (max-width: 750px) {
  h2 span {
    font-size: 42px;
    line-height: 1.2;
  }
}

p {
  font-size: 18px;
  font-family: "FuturaEUNormal";
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 750px) {
  p {
    font-size: 16px;
  }
}/*# sourceMappingURL=App.css.map */
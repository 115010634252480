.shop{
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
  color: #000000;
  @media screen and (max-width: 750px) {
    padding: 0 25px;
  }
  .shop-box{
    max-width: 1440px;
    width: 100%;
    padding: 0 50px;
    @media screen and (max-width: 750px) {
      padding: 0;
    }
    p{
      margin: 20px 0 50px 0;
    }
    .shop-baner{
      position: relative;
      img{
        width: 100%;
        image-rendering: pixelated;
      }
      .znaczki{
        position: absolute;
        width: auto;
        top: -64px;
        right: -2vw;
      }
      .tg{
        width: 100%;
        max-width: 185px;
        position: absolute;
        bottom: -100px;
        right: -7vw;
        z-index: -1;
        @media screen and (max-width: 750px) {
          display: none;
        }
      }
    }
  }
  .shop-btn-row{
    margin: 20px 0;
  }
}
.sticky-nav{
  .sticky-navbar-nav{
    padding: 0;
    height: 80px;
    width: 100%;
    @media screen and (max-width: 368px) {
      height: 100px;
    }
    .navigation{
      display: grid;
      grid-template-columns: 235px 1fr 235px;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-template-areas: ". . .";
      position: absolute;
      width: 100%;
      max-width: 1440px;
      margin: auto;
      left: 0;
      right: 0;
      color: #fff;
      align-items: center;
      @media screen and (max-width: 1440px) {
        padding: 0 25px;
      }
      @media screen and (max-width: 980px) {
        grid-template-columns: 1fr 1fr;
      }
      @media screen and (max-width: 368px) {
        grid-template-columns: 1fr;
        grid-template-areas: ".";
        display: grid;
        justify-items: center;
        gap: 10px;
      }
      .logo{
        width: 270px;
        img{
          width: 100%;
          max-width: 270px;
        }
        @media screen and (max-width: 570px) {
          img{
            max-width: 150px;
          }
        }
      }
      .top-menu{
        @media screen and (max-width: 980px) {
          display: none;
        }
        ul{
          display: flex;
          list-style: none;
          gap: 20px;
          align-items: center;
          margin: auto;
          padding: 0;
          justify-content: center;
          li{
            font-family: "FuturaHvEUNormal";
            text-transform: uppercase;
            font-size: 14px;
            a{
              transition: color ease .2s;
              color: #fff;
              text-decoration: none;
              &:hover{
                color: #5dd4e6
              }
            }
          }
        }        
      }

    }     
  }
 
}

.buy {
  display: flex;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
}
@media screen and (max-width: 1440px) {
  .buy {
    padding: 0 25px;
  }
}
@media screen and (max-width: 750px) {
  .buy {
    margin-top: 50px;
  }
}
.buy h2 {
  color: #000000;
}
.buy .buy-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .buy .buy-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px;
    grid-template-areas: ". .";
  }
}
@media screen and (max-width: 320px) {
  .buy .buy-row {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
.buy .buy-row > div {
  width: 100%;
}
.buy .buy-row > div > div {
  border-radius: 13px;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 17px 61px 32px rgba(0, 0, 0, 0.08);
  padding: 0 20px;
  transition: all ease-in-out 0.2s !important;
}
.buy .buy-row > div > div:hover {
  opacity: 0.8;
}
@media screen and (max-width: 750px) {
  .buy .buy-row > div > div img {
    max-width: 200px;
  }
}
.buy .buy-row .bbs {
  cursor: pointer;
  background-color: #000;
  color: #fff;
}
.buy .buy-row .bbs svg {
  width: 100%;
  max-width: 250px;
}
.buy .buy-row .bt {
  cursor: pointer;
  background: rgb(250, 225, 75);
  background: linear-gradient(90deg, rgb(250, 225, 75) 0%, rgb(244, 184, 31) 100%);
}
.buy .buy-row .bt img {
  width: 100%;
  max-width: 130px;
}
@media screen and (max-width: 750px) {
  .buy .buy-row .bt img {
    max-width: 90px;
  }
}
.buy .buy-row .cs {
  cursor: pointer;
  background-color: #fff;
}
.buy .buy-row .cs svg {
  width: 100%;
  max-width: 250px;
}
.buy .buy-row .ss {
  cursor: pointer;
  background: rgb(201, 247, 254);
  background: linear-gradient(90deg, rgb(201, 247, 254) 0%, rgb(141, 238, 252) 100%);
}
.buy .buy-row .ss svg {
  width: 100%;
  max-width: 270px;
}/*# sourceMappingURL=style.css.map */
.milestones {
  align-items: center;
  background: #000;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  width: 100%;
  margin: 80px auto;
}
@media screen and (max-width: 1440px) {
  .milestones {
    padding: 80px 0;
    margin: 0px;
    max-width: unset;
    border-radius: 0px;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 750px) {
  .milestones {
    max-width: unset;
    margin: 0px;
    padding-top: 50px;
    background: linear-gradient(360deg, #040709 0%, #162c35 100%);
  }
}
.milestones h2 {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  color: #fff;
  line-height: 1.1;
  margin-bottom: 0px;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .milestones h2 {
    padding: 0 25px;
    line-height: 0.7 !important;
  }
}
@media screen and (max-width: 750px) {
  .milestones h2 {
    margin-bottom: 20px;
    line-height: 0.9 !important;
  }
}
.milestones h2 span {
  color: #f6c21e;
}
@media screen and (max-width: 900px) {
  .milestones h2 span {
    line-height: 1;
    font-size: 25px;
  }
}
.milestones .img {
  background-image: url("./img/milestones-desktop.png");
  width: 100%;
  min-height: 1162px;
  background-position: center;
  background-size: 1916px;
  background-repeat: no-repeat;
  border-radius: 20px;
}
@media screen and (max-width: 1440px) {
  .milestones .img {
    background-image: none;
    min-height: unset;
  }
}
@media screen and (max-width: 700px) {
  .milestones .img {
    background-image: url("./img/milestones-mobile-2.png");
    min-height: 1470px;
    background-size: 668px;
    background-position: top center;
    border-radius: 0;
    margin-top: 20px;
  }
}/*# sourceMappingURL=style.css.map */
.stats {
  display: flex;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 80px;
}
@media screen and (max-width: 1440px) {
  .stats {
    padding: 0 25px;
  }
}
@media screen and (max-width: 750px) {
  .stats {
    margin-top: 50px;
  }
}
.stats hr {
  display: none;
}
@media screen and (max-width: 750px) {
  .stats hr {
    display: block;
  }
}
.stats .stats-row-2 {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px;
  grid-template-areas: ". . . .";
}
@media screen and (max-width: 750px) {
  .stats .stats-row-2 {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 40px 20px !important;
    grid-template-areas: ". .";
    justify-items: center;
    text-align: center;
    align-items: start !important;
  }
}
@media screen and (max-width: 750px) {
  .stats .stats-row-2 .stats-item-4 {
    gap: 20px !important;
  }
}
@media screen and (max-width: 750px) {
  .stats .stats-row-2 .stats-item-4 span {
    font-size: 16px !important;
    line-height: 1;
  }
}
@media screen and (min-width: 750px) {
  .stats .stats-row-2 .stats-item-4 span br {
    display: none;
  }
}
.stats .stats-row {
  display: flex;
  flex-direction: row;
  gap: 60px;
  justify-content: center;
  width: 100%;
  align-items: flex-end;
}
@media screen and (max-width: 750px) {
  .stats .stats-row {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 750px) {
  .stats .stats-row img {
    max-width: 95px;
  }
}
@media screen and (max-width: 750px) {
  .stats .stats-row {
    flex-direction: row;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
}
.stats .stats-row .stats-item-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.stats .stats-row .stats-item-4 span {
  font-family: "FuturaHvEU";
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
@media screen and (max-width: 1000px) {
  .stats .stats-row .stats-item-4 span {
    font-size: 17px;
  }
}
.stats .stats-row .stats-item-4 img {
  max-width: 95px;
}
.stats .stats-row .stats-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.stats .stats-row .stats-item .stats-item-text {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 22px 52px;
  gap: 0px;
  grid-template-areas: "." ".";
  justify-items: center;
}
.stats .stats-row .stats-item .stats-item-text b {
  font-size: 40px;
  letter-spacing: -1px;
}
@media screen and (max-width: 1250px) {
  .stats .stats-row .stats-item .stats-item-text b {
    font-size: 30px;
  }
}
@media screen and (max-width: 810px) {
  .stats .stats-row .stats-item .stats-item-text b {
    font-size: 20px;
  }
}
.stats .stats-row .stats-item span {
  font-family: "FuturaHvEU";
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
@media screen and (max-width: 1000px) {
  .stats .stats-row .stats-item span {
    font-size: 17px;
  }
}/*# sourceMappingURL=style.css.map */
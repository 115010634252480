.team {
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  gap: 30px;
  background-color: #1f82de;
  color: #fff;
  padding: 50px 50px;
  border-radius: 20px;
}
@media screen and (max-width: 750px) {
  .team {
    border-radius: 0px;
    padding: 50px 25px;
    margin-top: 0px;
  }
}
.team .team-box {
  width: 100%;
}
.team .team-box .sub-h2 {
  font-size: 33px;
  text-align: center;
  margin-top: 30px;
}
.team .team-box .team-row {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
@media screen and (max-width: 750px) {
  .team .team-box .team-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 28px 20px;
    grid-template-areas: ". .";
  }
}
.team .team-box .team-row .team-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
}
.team .team-box .team-row .team-item .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.team .team-box .team-row .team-item .text b.name {
  font-size: 12px;
}
.team .team-box .team-row .team-item .text span {
  font-size: 10px;
  color: #a2cef6;
}
.team .team-box .team-row .team-item .text span b {
  font-size: 11px;
}/*# sourceMappingURL=style.css.map */
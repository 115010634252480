.news{
  display: flex;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: -180px;
  @media screen and (max-width: 1440px) {
    padding: 0 25px;
  }
  @media screen and (max-width: 750px) {
    margin-top: -170px;
  }
  h2{
    color: #fff;
    text-shadow: 1px -11px 20px rgb(19 37 50 / 32%), 1px 2px 15px rgb(19 37 50 / 32%), 1px 9px 20px rgb(19 37 50 / 32%);
  }
  .news-row{
    display: grid; 
    grid-template-columns: 1fr ; 
    gap: 20px; 
    grid-template-areas: 
      "."; 
    width: 100%;
    margin: auto;
    @media screen and (max-width: 1200px) {
      display: grid; 
      grid-template-columns: 1fr ; 
      gap: 20px; 
      grid-template-areas: 
        "."; 
      width: 100%;
    }
    @media screen and (max-width: 320px) {
      display: grid; 
      grid-template-columns: 1fr ; 
      grid-template-areas: 
        "."; 
    }
    & > div{
      width: 100%;
    }
    & > div > div{
      border-radius: 13px;
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 17px 61px 32px rgba(0, 0, 0, 0.08);
      padding: 0;
      transition: all ease-in-out 0.2s !important;
      &:hover{
        opacity: .8;
      }
      img{
        @media screen and (max-width: 750px) {
          max-width: 200px;
        }
      }
    }
  }
  .news-box{
    max-width: 1000px;
    margin: 0 auto 50px auto;
    p{
      font-size: 14px;
      line-height: 1.5;
    }
    h3 {
      font-size: 35px;
      margin: 0 0 15px 0;
    }
    .react-player{
      height: 555px !important;
    }
  }
}

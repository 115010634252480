.roadmap{
  display: flex;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 80px;
  @media screen and (max-width: 1440px) {
    padding: 0 25px;
  }
  h2{
    margin-bottom: 20px;
    @media screen and (max-width: 750px) {
      text-align: center;
      line-height: 1.1;
      margin-bottom: 0px;
    }
  }
  .roadmap-grid{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 25px;
    grid-template-areas:
        ". ."
        ". .";
    @media screen and (max-width: 1150px) {
      grid-template-columns: 1fr;
      grid-template-areas:
          ".";
    }
    .roadmap-item{
      background: linear-gradient(45deg, #101e27 0%, #19323c 100%);
      border-radius: 20px;
      color: #fff;
      padding: 50px;
      font-family: "FuturaHvEU";
      @media screen and (max-width: 750px) {
        padding: 30px;
      }
      .title-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: -20px;
        h3{
          font-size: 32px;
          margin: 0;
          @media screen and (max-width: 750px) {
            padding-left: 0px;
            font-size: 22px;
          }
          span{
            color: #60d5e7;
          }
        }
        .meter{
          width: 80px;
          height: 80px; 
          position: relative;
          top: -25px;
          right: -15px;

          @media screen and (max-width: 750px) {
            width: 55px;
            top: -15px;
          }

          text{
            position: relative;
            top: 10px;
          }
        }
      }

      .title-row + ul {
        @media screen and (max-width: 750px) {
          padding-left: 18px;
        }
      }

      .checked{
        color:#60d5e7;
        position: relative;
        .icon-checked{
          position: absolute;
          margin-left: 4px;
          top: 2px;
          left: -26px;
          background: #152a33;
        }
      }

      ul{
        font-size: 14px;
        font-family: "FuturaEUNormal";
        font-weight: 600;
        list-style: none;
        // @media screen and (max-width: 750px) {
        //   padding-left: 25px;
        // }
        li::before {
          content: "•";
          color: #60d5e7;
          font-size: 17px;
          position: absolute;
          margin-left: -20px;
          top: -2px;
        }
        li{
          line-height: 1.4;
          position: relative;
          
          ul{
            li::before {
              content: "•";
              color: #60d5e7;
              font-size: 8px;
              position: absolute;
              margin-left: -16px;
              top: 4px;
            }
            li{
              ul{
                padding-top: 4px !important;
                padding-bottom: 4px !important;
                li::before {
                  content: "╾╾╾";
                  color: #60d5e7;
                  font-size: 8px;
                  position: absolute;
                  margin-left: -38px;
                  top: 4px;
                  @media screen and (max-width: 750px) {
                    content: "•";
                    margin-left: -16px;
                  }
                }
                li{
                  ul{
                    padding-left: 18px;
                    li::before {
                      content: "•";
                      color: #60d5e7;
                      font-size: 8px;
                      position: absolute;
                      margin-left: -16px;
                      top: 4px;
                    }
                  }
                }
              }
            }
          }
        }
        .light-font{
          font-weight: 400;
          padding-top: 10px;
          padding-bottom: 10px;
          @media screen and (max-width: 750px) {
            padding-left: 20px;
          }
          li{
            line-height: 1.3;
            padding-bottom: 8px;

          }
        }
      }
    }     
  }
}
.milestones{
  align-items: center;
  background: #000;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  width: 100%;
  margin: 80px auto;
  @media screen and (max-width: 1440px) {
    padding: 80px 0;
    margin: 0px;
    max-width: unset;
    border-radius: 0px;
    padding-bottom: 0;
  }
  @media screen and (max-width: 750px) {
    max-width: unset;
    margin: 0px;
    padding-top: 50px;
    background: linear-gradient(360deg, #040709 0%, #162c35 100%);
  }
  h2{
    width: 100%;
    max-width: 1440px;
    margin: auto;
    color: #fff;
    line-height: 1.1;
    margin-bottom: 0px;
    text-align: center;
    @media screen and (max-width: 1440px) {
      padding: 0 25px;
      line-height: .7 !important;
    }
    @media screen and (max-width: 750px) {
      margin-bottom: 20px;
      line-height: .9 !important;
    }
    span{
      color: #f6c21e;
      @media screen and (max-width: 900px) {
        line-height: 1;
        font-size: 25px;
      }
    }
  }
  .img{
    background-image: url('./img/milestones-desktop.png');
    width: 100%;
    min-height: 1162px;
    background-position: center;
    background-size: 1916px;
    background-repeat: no-repeat;
    border-radius: 20px;
    @media screen and (max-width: 1440px) {
      background-image: none;
      min-height: unset;
    }
    @media screen and (max-width: 700px) {
      background-image: url('./img/milestones-mobile-2.png');
      min-height: 1470px;
      background-size: 668px;
      background-position: top center;
      border-radius: 0;
      margin-top: 20px;
    }
  }
}
.whitepaper{
  display: flex;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 80px;
  @media screen and (max-width: 1440px) {
    padding: 0 25px;
  }
  @media screen and (max-width: 750px) {
    padding: 0px;
  }

  .balck-box{
    width: 100%;
    background-color: #101e27;
    padding: 50px;
    border-radius: 20px;
    @media screen and (max-width: 750px) {
      padding: 50px 25px;
      border-radius: 0px;
    }

    h2{
      text-align: center;
      line-height: 1;
      color: #fff;

    }
    p{
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      max-width: 750px;
      text-align: center;
      color: #fff;
      margin-left: auto;
      margin-right: auto;
      padding-top: 25px;
      padding-bottom: 35px;
    }

    .whitepaper-row{
      display: flex;
      flex-direction: row;
      gap: 20px;
      justify-content: space-between;
      width: 100%;


      @media screen and (max-width: 1000px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }

      & > div {
        width: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 17px;
        font-family: "FuturaHvEU";
        font-size: 20px;
        font-weight: 400;
        cursor: pointer;

        @media screen and (max-width: 1000px) {
          font-size: 15px;
        }

        @media screen and (max-width: 1000px) {
          font-size: 15px;
          gap: 10px;
          max-width: 45%;
        }
        img{
          @media screen and (max-width: 750px) {
            width: 70%;
          }
        }
      }
      & > div > div{
        border-radius: 13px;
        width: 100%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 17px 61px 32px rgb(0 0 0 / 8%);
        padding: 20px;
        transition: all ease-in-out .2s;
        &:hover{
          filter: brightness(.8);
        }
      }
      .icon1{
        background-color: #c62f28;
        background: linear-gradient(45deg, #c2001a 0%, #ff6124 100%);
        color: #fff;
      }
      .icon2{
        background: #6696f2;
        background: linear-gradient(45deg, #9a94ff 0%, #5e97f1 100%);
      }
      .icon3{
        background-color: #8dc73f;
        background: linear-gradient(45deg, #d7c618 0%, #8dc540 100%);
      }
      .icon4{
        background: #2b6aff;
        background: linear-gradient(45deg, #1531f4 0%, #4673f6 100%);
      }
      .icon5{
        background: #111116;
        img{
          max-width: 100px;
        }
      }
      .dex{
        background: #000000;
        background: linear-gradient(45deg, #00b8d8 0%, #049db7 100%);
      }
    }
  }

}
.contact {
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
  color: #000000;
}
@media screen and (max-width: 750px) {
  .contact {
    padding: 0px;
  }
}
.contact .contact-box {
  max-width: 1440px;
  width: 100%;
  padding: 0 50px;
}
@media screen and (max-width: 750px) {
  .contact .contact-box {
    padding: 0 25px;
  }
}
.contact .contact-box p {
  font-size: 16px;
}
@media screen and (max-width: 750px) {
  .contact .contact-box .form-row.filed {
    flex-direction: column !important;
  }
}
.contact .contact-box .form-row {
  display: flex;
  gap: 20px;
  position: relative;
}
.contact .contact-box .form-row p {
  padding-left: 30px;
}
.contact .contact-box .form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}
.contact .contact-box .form input[type=checkbox] {
  position: absolute !important;
  top: 0 !important;
}
.contact .contact-box .form input[type=text], .contact .contact-box .form textarea {
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 4px 15px 0 rgba(0, 0, 0, 0.06);
  font-family: "FuturaEUNormal";
  font-size: 15px;
}
.contact .contact-box .form input[type=text]:active, .contact .contact-box .form input[type=text]:focus, .contact .contact-box .form textarea:active, .contact .contact-box .form textarea:focus {
  outline-color: #1f82de;
}
.contact .contact-box .form textarea {
  min-height: 200px;
}
.contact .contact-box .form .btn-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}/*# sourceMappingURL=style.css.map */
.shop {
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
  color: #000000;
}
@media screen and (max-width: 750px) {
  .shop {
    padding: 0 25px;
  }
}
.shop .shop-box {
  max-width: 1440px;
  width: 100%;
  padding: 0 50px;
}
@media screen and (max-width: 750px) {
  .shop .shop-box {
    padding: 0;
  }
}
.shop .shop-box p {
  margin: 20px 0 50px 0;
}
.shop .shop-box .shop-baner {
  position: relative;
}
.shop .shop-box .shop-baner img {
  width: 100%;
  image-rendering: pixelated;
}
.shop .shop-box .shop-baner .znaczki {
  position: absolute;
  width: auto;
  top: -64px;
  right: -2vw;
}
.shop .shop-box .shop-baner .tg {
  width: 100%;
  max-width: 185px;
  position: absolute;
  bottom: -100px;
  right: -7vw;
  z-index: -1;
}
@media screen and (max-width: 750px) {
  .shop .shop-box .shop-baner .tg {
    display: none;
  }
}
.shop .shop-btn-row {
  margin: 20px 0;
}/*# sourceMappingURL=style.css.map */
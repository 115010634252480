.social {
  display: flex;
  justify-content: flex-end;
}
.social ul {
  display: flex;
  list-style: none;
  gap: 6px !important;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 570px) {
  .social ul {
    gap: 4px !important;
  }
}
@media screen and (max-width: 368px) {
  .social ul {
    gap: 6px !important;
  }
}
.social ul li a img {
  max-width: 28px;
  transition: all ease-in-out 0.2s;
}
@media screen and (max-width: 570px) {
  .social ul li a img {
    max-width: 20px;
  }
}
.social ul li a img:hover {
  filter: brightness(0.8);
}/*# sourceMappingURL=style.css.map */
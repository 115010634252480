.how-buy {
  max-width: 1440px;
  width: 100%;
  margin: 100px auto 0 auto;
  background-color: #000;
  border-radius: 20px;
  position: relative;
  @media screen and (max-width: 1440px) {
    width: calc(100% - 50px);
  }
  @media screen and (max-width: 890px) {
    padding: 40px 0;
    border-radius: 0;
    width: 100%;
  }
  .disc{
    position: absolute;
    top: -50px;
    left: -100px;
    @media screen and (max-width: 890px) {
      display: none;
    }
  }
  .how-buy-box {
    width: 100%;
    min-height: 700px;
    background-image: url('./img/bg.png');
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    color: #fff;
    padding: 50px 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: 
      ". .";
    @media screen and (max-width: 1080px) {
      padding: 20px;
      gap: 20px;
    }
    @media screen and (max-width: 890px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: 
        ".";
    }
    .instruction {
      @media screen and (max-width: 890px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .steps {
        display: flex;
        flex-direction: column;
        gap: 35px;
        margin-top: 50px;
        @media screen and (max-width: 890px) {
          align-items: center;
        }
        .step {
          @media screen and (max-width: 890px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
          }
          .steps-title {
            font-size: 34px;
            font-family: "Tr2n";
            color: #ffd803;
            text-shadow: 0px 0px 3px #ffa800, 0px 0px 3px #ffa800;
            margin-bottom: 10px;
          }

          p {
            font-size: 15px;
            max-width: 550px;
            line-height: 1.5;
            @media screen and (max-width: 1080px) {
              font-size: 13px;
            }
            a{
              color: #ffd803;
            }
          }
        }
      }
    }

    .video {
      position: relative;
      .video-title {
        display: inline-block;
        transform: rotate(90deg);
        position: absolute;
        right: -87px;
        top: 100px;
        color: #ffd804;
        @media screen and (max-width: 1440px) {
          right: -120px;
        }
        @media screen and (max-width: 1350px) {
          right: -140px;
        }
        @media screen and (max-width: 1250px) {
          right: -160px;
        }
        @media screen and (max-width: 1150px) {
          right: -170px;
        }
        @media screen and (max-width: 1080px) {
          transform: rotate(0deg);
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center;
        }
        @media screen and (max-width: 890px) {
          display: none;
        }
      }
      .video-box {
        position: absolute;
        width: 376px;
        height: 592px;
        left: 0;
        right: -151px;
        margin: auto;
        top: 0;
        bottom: 0;
        @media screen and (max-width: 1080px) {
          right: 0;
        }
        @media screen and (max-width: 890px) {
          position: relative;
        }
        @media screen and (max-width: 473px) {
          width: 100%;
          // height: 592px;
        }
        .cover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          z-index: 1;
          border-radius: 20px;
          border: 3px solid #bd9443;
          box-shadow: 0 0 20px 1px rgb(224 138 5 / 69%);
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 20px;
          }

          .play-button {
            position: absolute;
            background: transparent;
            border: none;
            padding: 10px 20px;
            font-size: 16px;
            cursor: pointer;
            border-radius: 5px;
            z-index: 2; // ensures the button is above the cover image
          }
        }

        .react-player {
          border-radius: 20px; // Dodanie border-radius do ReactPlayer
          overflow: hidden; // Aby upewnić się, że rogi wideo są zaokrąglone
        } 
      }
    }
  }
}
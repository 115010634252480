.tokenomics{
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
  background-color: #0b0b0b;
  color: #fff;
  padding: 50px 0;

  .tokenomics-box{
    max-width: 1440px;
    width: 100%;
    padding: 0 50px;
    @media screen and (max-width: 750px) {
      padding: 0px 25px;
    }
    .toke-content{
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 40px;

      h3{
        text-align: center;
        font-size: 22px;
      }

      .toke-row-4, .toke-row-3{
        display: flex;
        justify-content: space-between; 
        gap: 20px;
        .toke-item{
          font-size: 28px;
          font-weight: 700;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          border-radius: 12px;
          min-height: 100px;
          justify-content: center;
          text-align: center;
          @media screen and (max-width: 1400px) {
            font-size: 25px;
            padding: 0 20px;
          }
          @media screen and (max-width: 1300px) {
            font-size: 20px;
            word-break: break-all;
          }
        }  
      }
      .toke-row-4{
        @media screen and (max-width: 1300px) {
          display: grid; 
          grid-template-columns: 1fr 1fr; 
          grid-template-rows: 1fr; 
          gap: 20px; 
          grid-template-areas: 
            ". ."; 
        }
        @media screen and (max-width: 560px) {
          display: grid; 
          grid-template-columns: 1fr; 
          grid-template-rows: 1fr; 
          gap: 20px; 
          grid-template-areas: 
            "."; 
        }
        .toke-item{
          background-color: #313131;
          .mini {
            font-size: 14px;
            font-weight: 400;
            position: relative;
            top: 4px;
          }
          .toke-item-title{
            font-size: 16px;
            color: #ffd803;
          }
        }
      }
      .toke-row-3{
        @media screen and (max-width: 670px) {
          display: grid; 
          grid-template-columns: 1fr; 
          grid-template-rows: 1fr; 
          gap: 20px; 
          grid-template-areas: 
            "."; 
        }
        .toke-item{
          background-color: #ffd803;
          color: #0b0b0b;
        }
      }
      .chart{
        img{
          width: 100%;
          border-radius: 20px;
        }
      }  
      .btn-row {
        margin: 10px 0px;
        display: flex;
        justify-content: center;
      }    
    }
  }
}